<template>
  <div class="message-panel">
    <div class="error message" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <div class="success message" v-if="successMessage">
      {{ successMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageDisplay',

  props: [
    'errorMessage',
    'successMessage'
  ]
}
</script>

<style>
.error {
  background-color: red;
}
.success {
  background-color: green;
}
.message-panel {
  position: fixed;
  width: 300px;
  margin-left: -150px;
  bottom: 20px;
  left: 50%;
  z-index: 9999;
}
.message {
  padding: 10px 5px;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-weight: bold;
  opacity: 0.8;
}
</style>
