<template>
  <div>
    <LoadingBanner
      v-bind:title="'Password Request'" 
      v-bind:isLoading="isLoading"></LoadingBanner>

    <div class="alert alert-danger [ mt-3 ]" role="alert" v-if="updatePasswordErrors.length > 0">
      There were errors on the page:
      <ul class="[ m-0 ]">
        <li v-for="(error) in updatePasswordErrors" v-bind:key="error.id">
          {{ error.value }}
        </li>
      </ul>
    </div>
    <form @submit.prevent="onUpdatePassword" class="form-horizontal [ mt-3 ]">
      <div class="form-group">
        <label for="newPassword">New Password</label>
        <input v-model="password.newPassword" type="password" name="newPassword" id="newPassword" class="form-control" aria-describedby="newPassword">
      </div>
      <div class="form-group">
        <label for="passwordRepeat">Confirm Password</label>
        <input v-model="password.newPasswordConfirm" type="password" name="passwordRepeat" id="passwordRepeat" class="form-control" aria-describedby="passwordRepeat">
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary" role="button">Save</button>
      </div>
    </form>

    <MessageDisplay
      v-bind:errorMessage="errorMessage"
      v-bind:successMessage="successMessage"></MessageDisplay>
  </div>
</template>

<script>
import MessageDisplay from '@/components/MessageDisplay'
import LoadingBanner from '@/components/LoadingBanner'
import LoadingPanel from "@/components/LoadingPanel"
import { default as usersAPI } from '@/api/UsersAPI'

export default {
  name: 'UserPasswordResetContainer',

  components: {
    'MessageDisplay': MessageDisplay,
    'LoadingBanner': LoadingBanner,
    'LoadingPanel': LoadingPanel
  },

  data () {
    return {
      isLoading: false,
      errorMessage: null,
      successMessage: null,
      password: {},
      updatePasswordErrors: [],
    }
  },

  methods: {
    onUpdatePassword: function() {
      this.isLoading = true
      this.password.token = this.getTokenParam()
      usersAPI
        .resetPassword(this.password)
        .then(() => {
          this.$router.push({ name: 'login', params: { isRedirect: '2' }})
        })
        .catch((errors) => {
          this.updatePasswordErrors = errors.body.errors
        })
        .finally(() => this.isLoading = false)
    },

    getTokenParam() {
      return this.$route.params.token
    }
  }
}
</script>

<style>

</style>