<template>
  <div class="spacer-xs">
    <h4 v-if="title">{{title}}</h4>
    <hr v-if="!isLoading" />
    <div v-bind:class="{ loader: isLoading }"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingBanner',

  props: [
    'isLoading',
    'title'
  ]
}
</script>

<style scoped>
hr {
  margin: 0;
}
.loader {
  height: 1px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
}
.loader:before{
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 1px;
  background-color: #2980b9;
  animation: loading 2s linear infinite;
}
/* Smaller than tablet */
@media only screen and (max-width: 550px) {
  .spacer-xs {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@keyframes loading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}
</styles>
