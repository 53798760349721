<template>
  <div>
    <LoadingBanner
      v-bind:title="'Forgot Password Request'" 
      v-bind:isLoading="isLoading"></LoadingBanner>

    <div class="row [ mt-4 ]">
      <div class="col-sm-4"></div>
      <div class="col-sm-4">
        <div class="card">
          <article class="card-body">
            <h4 class="card-title text-center mb-4 mt-1">Reset Password</h4>
            <hr />
            <div v-if="isFoundError" class="alert alert-danger" role="alert">
              No emails found. Please try again.
            </div>

            <form @submit.prevent="onSubmit" class="form-group">
              <div class="form-group">
                <label for="email">Email</label>
                <input v-model="email" type="text" name="email" id="email" class="form-control" aria-describedby="email">
                <small id="email" class="text-muted">Please enter your email to recover your account.</small>
              </div>
              <div class="form-group text-center">
              <button type="submit" class="btn btn-primary btn-block" v-if="!isLoading">Send</button>
              <LoadingPanel v-if="isLoading"></LoadingPanel>
            </div>
            </form>
          </article>
        </div>
      </div>
      <div class="col-sm-4"></div>
    </div>

    <MessageDisplay
      v-bind:errorMessage="errorMessage"
      v-bind:successMessage="successMessage"></MessageDisplay>
  </div>
</template>

<script>
import MessageDisplay from '@/components/MessageDisplay'
import LoadingBanner from '@/components/LoadingBanner'
import LoadingPanel from "@/components/LoadingPanel";
import { default as usrAPI } from "@/api/UsersAPI";
import { setSuccessMessage, setErrorMessage } from '@/utils'


export default {
  name: "UserPasswordResetRequestContainer",

  components: {
    'MessageDisplay': MessageDisplay,
    'LoadingPanel': LoadingPanel,
    'LoadingBanner': LoadingBanner
  },

  data () {
    return {
      email: '',
      isFoundError: false,
      isLoading: false,
      errorMessage: null,
      successMessage: null,
    }
  },

  methods: {
    onSubmit: function() {
      this.isLoading = true
      usrAPI
        .sendForgotPasswordEmail(this.email)
        .then(() => {
          this.isFoundError = false
          setSuccessMessage(this, 'successMessage')
        })
        .catch((response) => {
          if (+response.status == 404) {
            this.isFoundError = true;
          } else {
            setErrorMessage(this, 'errorMessage')
          }
        })
        .finally(() => this.isLoading = false)
    }
  }
}
</script>

<style>

</style>